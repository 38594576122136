<template>
  <div class='indexbox'>
    <el-form ref="form" :model="form" :rules="rules" class="formbox">
      <div class="imgbox">
        <img alt="" src="../../assets/img/logo.png">
        <p>Create your account</p>
      </div>
      <el-form-item prop="user_name">
        <span class="form_tit">Your Full Name*</span>
        <el-input v-model="form.user_name" clearable></el-input>
      </el-form-item>
      <el-form-item prop="email">
        <span class="form_tit">Email*</span>
        <el-input v-model="form.email" clearable></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <span class="form_tit">Password*</span>
        <el-input v-model="form.password" clearable maxlength="18" show-password type="password"></el-input>
      </el-form-item>
      <el-form-item class="last" prop="re_password">
        <span class="form_tit">Confirm Password*</span>
        <el-input v-model="form.re_password" clearable maxlength="18" show-password type="password"></el-input>
      </el-form-item>
      <div class="password">
        <van-checkbox v-model="checked" checked-color="#19CBBF">
          I accept
          <router-link target='_blank' to="agreement?type=1"> The Terms of Use</router-link>
          and
          <router-link target='_blank' to="agreement?type=2"> Privacy Policy</router-link>
        </van-checkbox>
      </div>
      <el-form-item>
        <el-button :loading='loading' type="primary" @click.native.prevent="onSubmit">Register</el-button>
      </el-form-item>
      <div class="registerbox">
        <p>
          <router-link to="login">Login In</router-link>
        </p>
      </div>
    </el-form>
  </div>
</template>
<script>
import login from "@/views/login/login";
import {Checkbox, CheckboxGroup} from 'vant';

export default {
  components: {
    VanCheckbox: Checkbox,
    VanCheckboxGroup: CheckboxGroup,
  },
  data() {
    return {
      form: {
        user_name: '',
        email: '',
        password: '',
        re_password: '',
      },
      rules: {
        user_name: [
          {required: true, trigger: "blur", message: 'Please Enter Your Yull Name'}
        ],
        email: [
          {required: true, trigger: "blur", message: 'Please Enter Email'}
        ],
        password: [
          {required: true, trigger: "blur", message: 'Please Enter Password'}
        ],
        re_password: [
          {required: true, trigger: "blur", message: 'Please Enter Confirm  Password'}
        ],
      },
      checked: true,
      loading: false,
      radio: false
    };
  },
  computed: {},
  watch: {},
  methods: {
    input() {
      this.form.resource ? this.form.resource = true : this.form.resource = ''
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (!this.checked) return this.$notify({title: 'Error', message: 'Please agree to the regulation', type: 'warning'});
          this.loading = true
          this.$post({
            url: "/app/auth/register",
            data: this.form,
            success: () => {
              this.$message({
                message: 'Register Was Successful',
                type: 'success'
              });
              setTimeout(() => {
                this.$router.push('login')
                this.loading = false
              }, 1500);
            },
            tip: () => {
              setTimeout(() => {
                this.loading = false
              }, 1500);
            },
          })
        }
      });
    }
  },

}
</script>
<style lang='scss' scoped>
.indexbox {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20px;

  .formbox {
    width: 480px;
    padding: 10px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .imgbox {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 310px;
        height: 160px;
      }

      p {
        width: 100%;
        text-align: center;
        color: #3b3b3b;
        font-size: 26px;
        font-weight: 600;
        padding: 40px 0;
      }
    }

    .password {
      width: 100%;
      color: #3b3b3b;
      font-size: 16px;

      a {
        color: $color;
        font-size: 16px;
      }

      margin-bottom: 40px;
    }

    /deep/ .el-form-item {
      width: 100%;
      margin-bottom: 30px !important;

      .el-input {
        .el-input__inner {
          height: 50px;
        }
      }

      .el-form-item__content {
        width: 100%;

        .el-button {
          width: 100%;
          height: 48px;
          background: $color !important;
          border-radius: 5px;
          border-color: $color;
          font-size: 20px;
          font-weight: bold;
          color: #FFFFFF !important;
        }
      }
    }

    .registerbox {
      width: 100%;
      text-align: center;
      color: #3b3b3b;
      font-size: 16px;
      padding-top: 10px;

      a {
        color: $color;
        font-size: 16px;
      }
    }

    .last {
      margin-bottom: 16px !important;
    }

    .radio {
      /deep/ .el-radio__label {
        color: #3B3B3B;
      }

      .agreement {
        color: #19CBBF;
      }
    }


  }
}
</style>
