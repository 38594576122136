<template>
  <div class="indexbox">
    <el-form ref="form" :model="form" :rules="rules" class="formbox">
      <div class="imgbox">
        <img alt="" src="../../assets/img/logo.png" />
        <p>Sigin in Converting Order</p>
      </div>
      <el-form-item prop="email">
        <span class="form_tit">Email*</span>
        <el-input v-model="form.email" clearable></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <span class="form_tit">Password*</span>
        <el-input v-model="form.password" clearable maxlength="18" show-password type="password"></el-input>
      </el-form-item>
      <div class="password">
        <p @click="show = true">Forgot your password?</p>
      </div>
      <el-form-item>
        <el-button :loading="loading" type="primary" @click.native.prevent="onSubmit">Login</el-button>
      </el-form-item>
      <div class="registerbox">
        <p>
          No account
          <router-link to="register">Create new</router-link>
        </p>
      </div>
    </el-form>

    <el-dialog :visible.sync="show" title="Reset your password" width="30%">
      <el-form ref="sendform" :model="sendform" :rules="sendrules" class="formbox">
        <el-form-item prop="email">
          <span class="form_tit">Email*</span>
          <el-input v-model="sendform.email" clearable></el-input>
        </el-form-item>
        <el-form-item class="btnbox">
          <el-button :loading="loadinga" type="primary" @click.native.prevent="sendPasswordEmail">Send reset
            link</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
  import { setToken } from "@/utils/auth";

  export default {
    data() {
      return {
        form: {
          email: "",// christina.lee@vsmabrasives.com.cn
          password: "",// Vsm-62702643
        },
        loading: false,
        rules: {
          email: [
            {
              required: true,
              trigger: "blur",
              message: 'Please input a Email',
            },
          ],
          password: [
            {
              required: true,
              trigger: "blur",
              message: 'Please input a password',
            },
          ],
        },
        show: false,
        sendform: {
          email: "",
        },
        sendrules: {
          email: [
            {
              required: true,
              trigger: "blur",
              message: 'Please input a Email',
            },
          ],
        },
        loadinga: false,
      };
    },
    computed: {},
    watch: {},
    methods: {
      onSubmit() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.loading = true;
            this.$post({
              url: "/app/auth/login",
              data: this.form,
              success: (res) => {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                setToken(res.data.token);
                sessionStorage.setItem("activeIndex", "/");
                this.$store.dispatch("remove_API_ID");
                setTimeout(() => {
                  this.$router.replace("/");
                  this.loading = false;
                }, 1500);
              },
              tip: () => {
                setTimeout(() => {
                  this.loading = false;
                }, 1500);
              },
            });
          }
        });
      },
      sendPasswordEmail() {
        this.$refs.sendform.validate((valid) => {
          if (valid) {
            this.loadinga = true;
            this.$post({
              url: "/app/auth/sendPasswordEmail",
              data: this.sendform,
              success: () => {
                this.$message({
                  message: "Sent Successfully",
                  type: "success",
                });
                setTimeout(() => {
                  this.show = false;
                  this.loadinga = false;
                }, 1500);
              },
              tip: () => {
                setTimeout(() => {
                  this.loadinga = false;
                }, 1500);
              },
            });
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .indexbox {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 80px;
    .formbox {
      width: 480px;
      padding: 10px;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .imgbox {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 310px;
          height: 160px;
        }
        p {
          width: 100%;
          text-align: center;
          color: #3b3b3b;
          font-size: 26px;
          font-weight: 600;
          font-family: PingFang SC;
          padding: 40px 0;
        }
      }
      .password {
        width: 100%;
        p {
          color: $color;
          font-size: 16px;
          cursor: pointer;
        }
        margin-bottom: 40px;
      }
      /deep/ .el-form-item {
        width: 100%;
        margin-bottom: 30px !important;
        .el-input {
          .el-input__inner {
            height: 50px;
          }
        }
        .el-form-item__content {
          width: 100%;
          .el-button {
            width: 100%;
            height: 48px;
            background: $color !important;
            border-radius: 5px;
            border-color: $color;
            font-size: 20px;
            font-weight: bold;
            color: #ffffff !important;
          }
        }
      }
      .registerbox {
        width: 100%;
        text-align: center;
        color: #3b3b3b;
        font-size: 16px;
        padding-top: 10px;
        a {
          color: $color;
          font-size: 16px;
        }
      }
    }
  }
</style>
